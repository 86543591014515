var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dateCommon",
      staticClass: "com-contanier",
      style: { width: `${+_vm.width}px` },
    },
    [
      _c("el-date-picker", {
        attrs: {
          type: _vm.filterType,
          placeholder: _vm.startPlaceholder,
          "value-format": _vm.valueFormat,
          format: _vm.format,
          "picker-options": _vm.pickerOptions1,
        },
        model: {
          value: _vm.startDate,
          callback: function ($$v) {
            _vm.startDate = $$v
          },
          expression: "startDate",
        },
      }),
      _c("span", { staticClass: "range-separator" }, [
        _vm._v(_vm._s(_vm.rangeSeparator)),
      ]),
      _c("el-date-picker", {
        attrs: {
          type: _vm.filterType,
          placeholder: _vm.endPlaceholder,
          "value-format": _vm.valueFormat,
          format: _vm.format,
          "picker-options": _vm.pickerOptions2,
        },
        model: {
          value: _vm.endDate,
          callback: function ($$v) {
            _vm.endDate = $$v
          },
          expression: "endDate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }