<template>
  <div
    ref="dateCommon"
    class="com-contanier"
    :style="{ width: `${+width}px` }"
  >
    <el-date-picker
      v-model="startDate"
      :type="filterType"
      :placeholder="startPlaceholder"
      :value-format="valueFormat"
      :format="format"
      :picker-options="pickerOptions1"
    >
    </el-date-picker>
    <span class="range-separator">{{ rangeSeparator }}</span>
    <el-date-picker
      v-model="endDate"
      :type="filterType"
      :placeholder="endPlaceholder"
      :value-format="valueFormat"
      :format="format"
      :picker-options="pickerOptions2"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'date'
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    width: {
      type: [Number, String],
      default: '360'
    },
    rangeSeparator: {
      type: String,
      default: '-'
    },
    startPlaceholder: {
      type: String,
      default: '开始时间'
    },
    endPlaceholder: {
      type: String,
      default: '结束时间'
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    }
  },
  data() {
    return {
      startDate: '',
      endDate: ''
    }
  },
  computed: {
    pickerOptions1() {
      if (this.endDate) {
        return {
          disabledDate: (time) => {
            const endDate = new Date(this.endDate).getTime()
            return time.getTime() > endDate
          }
        }
      } else {
        return {
          disabledDate: null
        }
      }
    },
    pickerOptions2() {
      if (this.startDate) {
        return {
          disabledDate: (time) => {
            const startDate = new Date(this.startDate).getTime() - (24 * 60 * 60 * 1000)
            return time.getTime() < startDate
          }
        }
      } else {
        return {
          disabledDate: null
        }
      }
    },
    filterType() {
      const arr = ['year', 'month', 'dates', 'months', 'years', 'week', 'daterange', 'monthrange', 'datetimerange']
      return arr.indexOf(this.type) !== -1 ? 'date' : this.type
    }
  },
  watch: {
    startDate(v) {
      if (v && this.endDate) {
        this.$emit('input', [v, this.endDate])
      }
      if (!v || !this.endDate) {
        this.$emit('input', !v && !this.endDate ? '' : [v, this.endDate])
      }
    },
    endDate(v) {
      if (v && this.startDate) {
        this.$emit('input', [this.startDate, v])
      }
      if (!v || !this.startDate) {
        this.$emit('input', !v && !this.startDate ? '' : [this.startDate, v])
      }
    },
    '$attrs.value': {
      handler(v) {
        if (!v) {
          this.startDate = ''
          this.endDate = ''
        } else {
          this.startDate = v[0]
          this.endDate = v[1]
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang='less' scoped>
.com-contanier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .range-separator {
    margin: 0 7px;
  }
}
</style>
